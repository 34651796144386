/** @format */

const imagePaths = {
  avatar: "/images/avatar.png",
  homeBanner: "/images/home.png",
  homeBg: "/images/home_bg.png",
  aiChatbot: "/images/1.png",
  ecommercePlatform: "/images/2.png",
  realTimeAnalytics: "/images/3.png",
  mobileBankingApp: "/images/4.png",
  healthcareManagement: "/images/5.png",
  blockchainVoting: "/images/6.png"
};

export default imagePaths;
