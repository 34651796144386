/** @format */

import { default as defaultTheme } from "react-treebeard/dist/themes/default";

export const treeStyle = {
  ...defaultTheme,
  tree: {
    ...defaultTheme.tree,
    base: {
      ...defaultTheme.tree.base,
      backgroundColor: "transparent" // Override background to be transparent
    }
  }
};
