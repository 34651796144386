/** @format */

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@utils/firebaseConfig";
import Button from "@components/common/Button";
import MarkdownContent from "@components/MarkdownContent";
import imagePaths from "@data/imagePaths";
import { calculateReadingTime } from "@utils/function";

interface BlogPostParams extends Record<string, string | undefined> {
  id: string;
}

interface PostData {
  title: string;
  content: string;
  timestamp: {
    toDate: () => Date;
  };
  imageUrl: string;
}

const BlogPost: React.FC = () => {
  const { id } = useParams<BlogPostParams>();
  const [post, setPost] = useState<PostData | null>(null);
  const [readingTime, setReadingTime] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      if (id) {
        const docRef = doc(db, "posts", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const postData = docSnap.data() as PostData;
          setPost(postData);
          const time = calculateReadingTime(postData.content);
          setReadingTime(time);
        }
      }
    };
    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto" id="blog-post">
      <h2 className="text-5xl font-bold kode-mono-bold mb-6">{post.title}</h2>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <img
            src={imagePaths.avatar}
            alt="Author Avatar"
            className="rounded-full w-14 h-14"
          />
          <div className="ml-4">
            <span className="block text-lg font-medium text-gray-700 dark:text-gray-300">
              Jason Lee
            </span>
            <span className="block text-lg font-medium text-gray-700 dark:text-gray-300">
              {readingTime} min read ·{" "}
              {new Date(post.timestamp.toDate()).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
              })}
            </span>
          </div>
        </div>
        <Link to="/blog">
          <Button variant="back">← List</Button>
        </Link>
      </div>
      <MarkdownContent content={post.content} imageUrl={post.imageUrl} />
    </div>
  );
};

export default BlogPost;
