/** @format */

import React, { useState, useEffect } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the root element for accessibility

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: {
    image: string;
    title: string;
    description: string;
    date: string;
  } | null;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  content
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 250); // Match this duration with the modal close animation duration
  };

  return (
    <Modal
      isOpen={isOpen && !isClosing}
      onRequestClose={handleClose}
      contentLabel="Portfolio Item"
      className={`modal ${isClosing ? "modal-close" : "modal-open"}`}
      overlayClassName="modal-overlay"
      closeTimeoutMS={250} // Match this duration with the modal close animation duration
    >
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      {content && (
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <img
              src={content.image}
              alt={content.title}
              className="w-full h-auto object-contain rounded-lg"
            />
          </div>
          <div className="md:w-1/2 md:pl-4">
            <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              {content.description}
            </p>
            <p className="text-gray-600 dark:text-gray-400 mt-4">
              {content.date}
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CustomModal;
