/** @format */

import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import imagePaths from "@data/imagePaths";
import VisitorsWidget from "@components/VisitorWidget";

const Home = () => {
  const [showSecondLine, setShowSecondLine] = useState(false);
  const [showThirdLine, setShowThirdLine] = useState(false);

  useEffect(() => {
    const secondLineTimer = setTimeout(() => {
      setShowSecondLine(true);
    }, 1000); // 첫 번째 줄 타이핑 시간에 맞춰 조정

    const thirdLineTimer = setTimeout(() => {
      setShowThirdLine(true);
    }, 4500); // 두 번째 줄 타이핑 시간에 맞춰 조정

    return () => {
      clearTimeout(secondLineTimer);
      clearTimeout(thirdLineTimer);
    };
  }, []);

  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${imagePaths.homeBg})` }}
      id="home"
    >
      <div className="bg-white bg-opacity-70 p-8 rounded-lg shadow-lg max-w-screen-lg mx-auto text-center">
        <div className="text-5xl text-gray-700 dark:text-gray-700 mb-6 kode-mono-bold">
          <TypeAnimation
            sequence={["Hello world!"]}
            wrapper="div"
            cursor={false}
            speed={60}
            repeat={0}
          />
        </div>
        <div className="text-lg text-gray-700 dark:text-gray-700 mb-6 courier-prime-regular leading-tight">
          {showSecondLine && (
            <TypeAnimation
              sequence={[
                "This is a place to organize, concretize, and share extremely personal thoughts."
              ]}
              wrapper="div"
              cursor={false}
              speed={60}
              repeat={0}
            />
          )}
          {showThirdLine && (
            <TypeAnimation
              sequence={[
                "Any questions about me, please chat with AI chatbot ⇘."
              ]}
              wrapper="div"
              cursor={true}
              speed={60}
              repeat={0}
            />
          )}
        </div>
        <VisitorsWidget /> {/* 방문자 수 위젯 추가 */}
      </div>
    </div>
  );
};

export default Home;
