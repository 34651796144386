/** @format */

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Home from "@pages/Home";
import Blog from "@pages/Blog";
import AddPost from "@pages/AddPost";
import BlogPost from "@pages/BlogPost";
import Portfolio from "@pages/Portfolio";
import AboutMe from "@pages/AboutMe";
import Personality from "@pages/Personality";
import NavBar from "@components/NavBar";
import Footer from "@components/Footer";
import { AuthProvider } from "@context/AuthContext";

const MainContent: React.FC = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <main className={`flex-grow ${isHomePage ? "" : "mt-32 mb-32"}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/add" element={<AddPost />} />
        <Route path="/blog/modify/:id" element={<AddPost />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/career" element={<AboutMe />} />
        <Route path="/personality" element={<Personality />} />
      </Routes>
    </main>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <NavBar />
          <MainContent />
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
