/** @format */

import React, { useState, useEffect } from "react";
import { addDoc, collection, updateDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams, Link } from "react-router-dom";
import { db } from "@utils/firebaseConfig";
import Button from "@components/common/Button";
import Modal from "react-modal";
import MarkdownContent from "@components/MarkdownContent";
import { useAuth } from "@context/AuthContext";

interface AddPostParams {
  id?: string;
  [key: string]: string | undefined;
}

interface PostData {
  title: string;
  content: string;
  imageUrl: string;
}

const AddPost: React.FC = () => {
  const { id } = useParams<AddPostParams>();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchPost = async () => {
        const docRef = doc(db, "posts", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const postData = docSnap.data() as PostData;
          setTitle(postData.title);
          setContent(postData.content);
          setImageUrl(postData.imageUrl);
        }
      };
      fetchPost();
    }
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !content) {
      alert("Title and content are required.");
      return;
    }

    const postPayload = {
      title,
      content,
      imageUrl,
      timestamp: new Date()
    };

    if (id) {
      await updateDoc(doc(db, "posts", id), postPayload);
      alert("Post updated successfully!");
    } else {
      await addDoc(collection(db, "posts"), postPayload);
      alert("Post added successfully!");
    }
    navigate("/blog");
  };

  const openPreview = () => {
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setTimeout(() => {
      setIsPreviewOpen(false);
    }, 250);
  };

  if (!isAdmin) {
    return (
      <p className="text-red-500">
        You are not authorized to add or modify posts.
      </p>
    );
  }

  const renderInput = (
    label: string,
    id: string,
    value: string,
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void,
    type: "text" | "textarea" = "text",
    rows?: number
  ) => (
    <div className="w-full mb-4">
      <label
        htmlFor={id}
        className="block text-gray-700 dark:text-gray-300 font-semibold mb-2"
      >
        {label}
      </label>
      {type === "textarea" ? (
        <textarea
          id={id}
          className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none"
          value={value}
          onChange={onChange}
          rows={rows}
        ></textarea>
      ) : (
        <input
          type={type}
          id={id}
          className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none"
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );

  return (
    <div className="container mx-auto" id="add-post">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-5xl font-bold kode-mono-bold">
          {id ? "Modify Post" : "Add Post"}
        </h2>
        <Link to="/blog">
          <Button variant="back">← List</Button>
        </Link>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col w-full space-y-4">
        {renderInput("Title", "title", title, (e) => setTitle(e.target.value))}
        {renderInput(
          "Content",
          "content",
          content,
          (e) => setContent(e.target.value),
          "textarea",
          10
        )}
        {renderInput("Image URL", "imageUrl", imageUrl, (e) =>
          setImageUrl(e.target.value)
        )}
        <div className="flex justify-between">
          <Button type="button" variant="secondary" onClick={openPreview}>
            Preview
          </Button>
          <Button type="submit" variant="primary">
            {id ? "Update Post" : "Add Post"}
          </Button>
        </div>
      </form>

      <Modal
        isOpen={isPreviewOpen}
        onRequestClose={closePreview}
        contentLabel="Preview Post"
        className={`modal preview-modal ${
          isPreviewOpen ? "modal-open" : "modal-close"
        } container mx-auto`}
        overlayClassName={`modal-overlay ${
          document.documentElement.classList.contains("dark") ? "dark" : ""
        }`}
      >
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-4xl font-bold kode-mono-bold">{title}</h3>
          <button onClick={closePreview} className="close-button">
            X
          </button>
        </div>
        <MarkdownContent content={content} imageUrl={imageUrl} />
      </Modal>
    </div>
  );
};

export default AddPost;
