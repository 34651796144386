/** @format */

import React from "react";

interface ButtonProps {
  variant: "primary" | "secondary" | "back";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit";
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  onClick,
  type = "button",
  children
}) => {
  let className = "";

  switch (variant) {
    case "primary":
      className =
        "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded";
      break;
    case "secondary":
      className =
        "bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded";
      break;
    case "back":
      className =
        "bg-transparent hover:bg-gray-200 text-blue-500 font-bold py-2 px-4 rounded";
      break;
    default:
      break;
  }

  return (
    <button className={className} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

export default Button;
