/** @format */

import { useEffect, useState } from "react";

interface AnalyticsData {
  todayVisitors: string;
  totalVisitors: string;
}

const VisitorsWidget = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(
    null
  );

  useEffect(() => {
    fetch(
      "https://us-central1-portfolio-b9363.cloudfunctions.net/getTodayVisitors"
    )
      .then((response) => response.json())
      .then((data) => setAnalyticsData(data))
      .catch((error) => console.error("Error fetching visitors:", error));
  }, []);

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
      <h2 className="text-2xl font-bold mb-4 kode-mono-bold">
        Visitor Statistics
      </h2>
      <p className="text-xl">
        Today's Visitors:{" "}
        {analyticsData ? analyticsData.todayVisitors : "Loading..."}
      </p>
      <p className="text-xl">
        Total Visitors:{" "}
        {analyticsData ? analyticsData.totalVisitors : "Loading..."}
      </p>
    </div>
  );
};

export default VisitorsWidget;
