/** @format */

import React, { useState } from "react";
import { Treebeard, TreeNode } from "react-treebeard";
import { treeStyle } from "./treeStyle";

const data: TreeNode = {
  name: "root",
  toggled: true,
  children: [
    {
      name: "2021",
      children: [
        {
          name: "1기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "정수" },
                { name: "종수" },
                { name: "정식" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "순자" },
                { name: "영숙" },
                { name: "영순" },
                { name: "영자" },
                { name: "정숙" },
                { name: "정순" },
                { name: "정자" }
              ]
            }
          ]
        },
        {
          name: "2기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "정수" },
                { name: "종수" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "순자" },
                { name: "정순" },
                { name: "영자" },
                { name: "정숙" },
                { name: "영숙" }
              ]
            }
          ]
        },
        {
          name: "3기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "정수" },
                { name: "종수" },
                { name: "정식" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "순자" },
                { name: "정순" },
                { name: "영자" },
                { name: "정자" },
                { name: "영숙" },
                { name: "정숙" }
              ]
            }
          ]
        },
        {
          name: "4기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "정수" },
                { name: "정식" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "정순" },
                { name: "영숙" },
                { name: "정자" }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "2022",
      children: [
        {
          name: "5기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "정수" },
                { name: "정식" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "순자" },
                { name: "영자" },
                { name: "정순" },
                { name: "정숙" },
                { name: "영숙" },
                { name: "정자" }
              ]
            }
          ]
        },
        {
          name: "6기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "옥순" },
                { name: "영숙" },
                { name: "정숙" },
                { name: "영자" },
                { name: "순자" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "7기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "광수" },
                { name: "상철" },
                { name: "경수" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" }
              ]
            }
          ]
        },
        {
          name: "8기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "9기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "광수" },
                { name: "영호" },
                { name: "영철" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "10기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "11기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "2023",
      children: [
        {
          name: "12기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영식" },
                { name: "영철" },
                { name: "영호" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "13기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "14기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "15기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "16기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "17기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "영자" },
                { name: "옥순" },
                { name: "순자" },
                { name: "현숙" }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "2024",
      children: [
        {
          name: "18",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "19기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        },
        {
          name: "20기",
          children: [
            {
              name: "남",
              children: [
                { name: "영수" },
                { name: "영호" },
                { name: "영식" },
                { name: "영철" },
                { name: "광수" },
                { name: "상철" }
              ]
            },
            {
              name: "여",
              children: [
                { name: "영숙" },
                { name: "정숙" },
                { name: "순자" },
                { name: "영자" },
                { name: "옥순" },
                { name: "현숙" }
              ]
            }
          ]
        }
      ]
    }
  ]
};

const MenuTree: React.FC = () => {
  const [treeData, setTreeData] = useState<TreeNode>(data);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);

  const onToggle = (node: TreeNode, toggled: boolean) => {
    if (node.children) {
      node.toggled = toggled;
    }
    setTreeData({ ...treeData });
    setSelectedMenu(node.name);
  };

  const contentMap: { [key: string]: string } = {
    A: "Content for A",
    "A-1": "Content for A-1",
    "A-2": "Content for A-2",
    B: "Content for B",
    "B-1": "Content for B-1",
    "B-2": "Content for B-2",
    C: "Content for C",
    "C-1": "Content for C-1",
    "C-2": "Content for C-2"
  };

  const renderCardContent = (menu: string | null) => {
    if (menu && contentMap[menu]) {
      return <div>{contentMap[menu]}</div>;
    } else {
      return <div>Please select a menu item</div>;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "200px" }}>
        {/* @ts-ignore */}
        <Treebeard data={treeData} onToggle={onToggle} style={treeStyle} />
      </div>
      <div
        style={{
          marginLeft: "20px",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          width: "100%"
        }}
      >
        {renderCardContent(selectedMenu)}
      </div>
    </div>
  );
};

export default MenuTree;
