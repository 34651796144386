/** @format */

import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db, auth, googleProvider } from "@utils/firebaseConfig";
import Button from "@components/common/Button";
import { useAuth } from "@context/AuthContext";
import { signInWithPopup, signOut } from "firebase/auth";

interface Post {
  id: string;
  title: string;
  content: string;
  timestamp: {
    toDate: () => Date;
  };
  imageUrl?: string;
}

const Blog: React.FC = () => {
  const { isAdmin, setIsAdmin } = useAuth();
  const [posts, setPosts] = useState<Post[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = await getDocs(collection(db, "posts"));
      setPosts(
        postsCollection.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as Post)
        )
      );
    };
    fetchPosts();
  }, []);

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      await deleteDoc(doc(db, "posts", id));
      setPosts(posts.filter((post) => post.id !== id));
    }
  };

  const handleNavigate = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    // Prevent navigation if the clicked element is a button
    if ((e.target as HTMLElement).tagName.toLowerCase() !== "button") {
      navigate(`/blog/${id}`);
    }
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (user.email === process.env.REACT_APP_ADMIN_EMAIL) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        alert("You are not authorized to add posts.");
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsAdmin(false);
      alert("Logged out successfully.");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="container mx-auto py-24 px-6 mt-16" id="blog">
      <div className="flex justify-between items-center">
        <h2 className="text-5xl font-bold mb-6 kode-mono-bold">Blog</h2>
        <div className="flex space-x-4">
          {isAdmin ? (
            <>
              <Link to="/blog/add">
                <Button variant="primary">Write Article</Button>
              </Link>
              <Button variant="secondary" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <Button onClick={handleLogin} variant="primary">
              Login with Google
            </Button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {posts.map((post) => (
          <div
            key={post.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 relative cursor-pointer flex flex-col justify-between"
            onClick={(e) => handleNavigate(e, post.id)}
          >
            <div>
              <h3 className="text-3xl font-semibold mb-4 kode-mono-bold">
                {post.title}
              </h3>
              {post.imageUrl && (
                <img
                  src={post.imageUrl}
                  alt={post.title}
                  className="w-full h-48 object-cover mt-6 rounded-lg shadow-lg"
                />
              )}
            </div>
            <div className="flex justify-end">
              <span className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                {new Date(post.timestamp.toDate()).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })}
              </span>
            </div>
            {isAdmin && (
              <div className="flex justify-between mt-4">
                <Link
                  to={`/blog/modify/${post.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button variant="secondary">Modify</Button>
                </Link>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(post.id);
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
