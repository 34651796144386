/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaSun, FaMoon, FaBars } from "react-icons/fa";
import { useAuth } from "@context/AuthContext";

const menuLinks = [
  { to: "/", label: "Home" },
  { to: "/career", label: "About Me" },
  { to: "/blog", label: "Blog" },
  { to: "/portfolio", label: "Portfolio" },
  { to: "/personality", label: "Personality" }
];

const linkClassName = "hover:text-gray-300 font-bold kode-mono-bold";
const mobileLinkClassName =
  "block px-4 py-2 text-sm text-white hover:bg-blue-700";

const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { darkMode, setDarkMode } = useAuth();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <nav className="bg-blue-800 dark:bg-blue-900 text-white py-3 shadow-md fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="flex items-center justify-end w-full space-x-8">
          <div className="hidden md:flex space-x-8">
            {menuLinks.map((link) => (
              <Link key={link.to} to={link.to} className={linkClassName}>
                {link.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex items-center space-x-4 ml-6">
          <button
            onClick={() => setDarkMode(!darkMode)}
            className="text-2xl focus:outline-none"
          >
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>
          <button
            onClick={toggleMenu}
            className="md:hidden text-2xl focus:outline-none"
          >
            <FaBars />
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div ref={menuRef} className="md:hidden">
          {menuLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={mobileLinkClassName}
              onClick={toggleMenu}
            >
              {link.label}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
};

export default NavBar;
