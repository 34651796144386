/** @format */

import React, { useState } from "react";
import CustomModal from "@components/common/CustomModal";
import portfolioData from "@data/portfolioData";

const Portfolio: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<
    (typeof portfolioData)[0] | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (item: (typeof portfolioData)[0]) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSelectedItem(null);
    }, 250); // Match this duration with the modal close animation duration
  };

  return (
    <div className="container mx-auto" id="portfolio">
      <h2 className="text-5xl font-bold mb-6 kode-mono-bold">Portfolio</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {portfolioData.map((item, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg cursor-pointer"
            onClick={() => openModal(item)}
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2 kode-mono-bold">
                {item.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400">{item.date}</p>
            </div>
          </div>
        ))}
      </div>
      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={selectedItem}
      />
    </div>
  );
};

export default Portfolio;
