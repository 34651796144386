/** @format */

import React from "react";

const Footer: React.FC = () => (
  <footer className="bg-blue-800 dark:bg-blue-900 text-white py-3 mt-auto">
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
      <span>© 2024 Jason Lee. All Rights Reserved.</span>
      <div className="flex space-x-4 mt-2 md:mt-0">
        <a href="mailto:aka.hamm@gmail.com" className="hover:text-gray-300">
          aka.hamm@gmail.com
        </a>
        <a
          href="https://www.linkedin.com/in/hm-jasonlee/"
          className="hover:text-gray-300"
        >
          LinkedIn
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
