/** @format */

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect
} from "react";
import { User } from "firebase/auth";
import { useFirebaseAuth } from "@utils/firebaseConfig";

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  setIsAdmin: (isAdmin: boolean) => void;
  darkMode: boolean;
  setDarkMode: (mode: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const currentUser = useFirebaseAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.email === process.env.REACT_APP_ADMIN_EMAIL
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const root = window.document.documentElement;
    if (darkMode) {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <AuthContext.Provider
      value={{ currentUser, isAdmin, setIsAdmin, darkMode, setDarkMode }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
