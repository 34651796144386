/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MarkdownContentProps {
  content: string;
  imageUrl?: string;
}

const MarkdownContent: React.FC<MarkdownContentProps> = ({
  content,
  imageUrl
}) => (
  <div className="prose max-w-none dark:prose-dark">
    {imageUrl && (
      <img src={imageUrl} alt="Post" className="w-full rounded-lg mb-4" />
    )}
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
  </div>
);

export default MarkdownContent;
