/** @format */

import imagePaths from "./imagePaths";

const portfolioData = [
  {
    title: "AI-Powered Chatbot",
    image: imagePaths.aiChatbot,
    date: "January 2021",
    description:
      "Developed an AI-powered chatbot for customer service that reduced response time by 50%."
  },
  {
    title: "E-commerce Platform",
    image: imagePaths.ecommercePlatform,
    date: "March 2021",
    description:
      "Led the development of a scalable e-commerce platform used by over 10,000 customers daily."
  },
  {
    title: "Real-Time Analytics Tool",
    image: imagePaths.realTimeAnalytics,
    date: "May 2021",
    description:
      "Created a real-time analytics tool for monitoring and analyzing web traffic and user behavior."
  },
  {
    title: "Mobile Banking App",
    image: imagePaths.mobileBankingApp,
    date: "July 2021",
    description:
      "Designed and implemented a secure mobile banking app with over 100,000 downloads."
  },
  {
    title: "Healthcare Management System",
    image: imagePaths.healthcareManagement,
    date: "September 2021",
    description:
      "Developed a comprehensive healthcare management system for hospitals and clinics."
  },
  {
    title: "Blockchain-Based Voting System",
    image: imagePaths.blockchainVoting,
    date: "November 2021",
    description:
      "Built a secure and transparent blockchain-based voting system."
  }
];

export default portfolioData;
