/** @format */

import React from "react";

const Personality: React.FC = () => (
  <div className="container mx-auto" id="personality">
    <h2 className="text-5xl font-bold mb-6 kode-mono-bold">
      Personality and Preferences
    </h2>
    <div className="text-lg text-gray-700 dark:text-gray-300 mb-6">
      <p className="mb-4">
        Hi, I'm Jason Hyunmyung Lee. As a front-end engineer and the CEO of
        DA:Ground, I bring a unique blend of technical skills and creative
        thinking to my work. I enjoy being in dynamic and innovative
        environments where I can learn and grow.
      </p>
      <p className="mb-4">
        In my free time, I like to explore new technologies and stay updated
        with the latest trends in IT and AI. I am passionate about using
        technology to solve real-world problems and enhance people's lives.
      </p>
      <p className="mb-4">
        I have a keen interest in art and design, which often influences my
        approach to front-end development. I believe a well-designed interface
        can significantly improve the user experience.
      </p>
      <p className="mb-4">
        Outside of work, I enjoy traveling and experiencing different cultures,
        which gives me new perspectives and ideas that I bring back to my
        professional work. I am also an avid reader and enjoy books on
        technology, psychology, and business strategy.
      </p>
    </div>
    <iframe
      src="https://www.chatbase.co/chatbot-iframe/4RuM4oTZ92ifYApbZAOcw"
      title="Chatbot"
      width="100%"
      style={{ height: "100%", minHeight: "700px" }}
      frameBorder="0"
    ></iframe>
  </div>
);

export default Personality;
